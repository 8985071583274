export * from 'cf-core/src/images'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/sushihowe/app-store.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/sushihowe/cflogo.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/sushihowe/cftitle.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/sushihowe/google-play.png'
export const smallLogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1560647248/tomsushi/smallLogo.jpg'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/sushihowe/user.png'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
// export const orderButton =
//   'https://res.cloudinary.com/codefusiontech/image/upload/v1555654769/sakemaki/orderButton.png'
// export const downloadButton =
//   'https://res.cloudinary.com/codefusiontech/image/upload/v1555654761/sakemaki/downloadButton.png'
export const about =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/tomsushi/about.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/tomsushi/restaurant.png'
export const chefbg = 'https://d7v5v69jwy75x.cloudfront.net/tomChefBg.webp'
export const features = 'https://d7v5v69jwy75x.cloudfront.net/features.png'
export const navbg = 'https://d7v5v69jwy75x.cloudfront.net/navbg.jpg'
export const logo = 'https://d7v5v69jwy75x.cloudfront.net/logo.png'
export const hero = 'https://d7v5v69jwy75x.cloudfront.net/heroBg.webp'
export const woodbg = 'https://d7v5v69jwy75x.cloudfront.net/woodbg.jpg'
export const containerbg =
  'https://d7v5v69jwy75x.cloudfront.net/containerbg.png'
export const about1 = 'https://d7v5v69jwy75x.cloudfront.net/tomAbout1.webp'
export const about2 = 'https://d7v5v69jwy75x.cloudfront.net/tomAbout2.webp'
export const about3 = 'https://d7v5v69jwy75x.cloudfront.net/tomAbout3.webp'
export const locationMap =
  'https://d7v5v69jwy75x.cloudfront.net/locationMap.webp'
export const restaurant1 =
  'https://d7v5v69jwy75x.cloudfront.net/tomRestaurant2.webp'
export const restaurant2 =
  'https://d7v5v69jwy75x.cloudfront.net/tomRestaurant3.webp'
export const restaurant3 =
  'https://d7v5v69jwy75x.cloudfront.net/tomRestaurant1.webp'
export const feature1 = 'https://d7v5v69jwy75x.cloudfront.net/tomFeature1.webp'
export const feature2 = 'https://d7v5v69jwy75x.cloudfront.net/tomFeature2.webp'
export const feature3 = 'https://d7v5v69jwy75x.cloudfront.net/tomFeature3.webp'
export const feature4 = 'https://d7v5v69jwy75x.cloudfront.net/tomFeature4.webp'
export const feature5 = 'https://d7v5v69jwy75x.cloudfront.net/tomFeature5.webp'
export const feature6 = 'https://d7v5v69jwy75x.cloudfront.net/tomFeature6.webp'
export const chefcontent =
  'https://d7v5v69jwy75x.cloudfront.net/chefcontent.png'
export const email = 'https://d7v5v69jwy75x.cloudfront.net/email.png'
export const facebook = 'https://d7v5v69jwy75x.cloudfront.net/facebook.png'
export const instagram = 'https://d7v5v69jwy75x.cloudfront.net/instagram.png'
export const twitter = 'https://d7v5v69jwy75x.cloudfront.net/twitter.png'
export const yelp = 'https://d7v5v69jwy75x.cloudfront.net/yelp.png'
export const rewardbg = 'https://d7v5v69jwy75x.cloudfront.net/rewardbg.jpg'
export const pointsMeter =
  'https://d7v5v69jwy75x.cloudfront.net/pointsMeter.png'
export const rewardArrowLeft =
  'https://d7v5v69jwy75x.cloudfront.net/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://d7v5v69jwy75x.cloudfront.net/rewardArrowRight.png'
export const redeemButton =
  'https://d7v5v69jwy75x.cloudfront.net/redeemButton.png'
export const rewardDefault =
  'https://d7v5v69jwy75x.cloudfront.net/rewardDefault.png'
export const dashDelivery =
  'https://d7v5v69jwy75x.cloudfront.net/dashDelivery.png'
export const orderButton =
  'https://d7v5v69jwy75x.cloudfront.net/pickupButton.png'
export const downloadButton =
  'https://d7v5v69jwy75x.cloudfront.net/downloadButton.png'
