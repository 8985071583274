import React, { Component } from 'react'
import { css } from 'emotion'
import { MediaQuery } from 'components'
import {
  feature1,
  feature2,
  feature3,
  feature4,
  feature5,
  feature6,
} from 'images'
import { MEDIA_QUERY } from 'styles'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  pauseOnHover: false,
  draggable: true,
  swipe: true,
}

export default class Features extends Component {
  render() {
    return (
      <div className={styles.container}>
        <MediaQuery maxWidth={767}>
          {matches => {
            if (matches) {
              return (
                <Slider {...{ ...settings, slidesToShow: 2 }}>
                  <img
                    src={feature1}
                    alt="Features"
                    className={styles.feature}
                  />
                  <img
                    src={feature2}
                    alt="Features"
                    className={styles.feature}
                  />
                  <img
                    src={feature3}
                    alt="Features"
                    className={styles.feature}
                  />
                  <img
                    src={feature4}
                    alt="Features"
                    className={styles.feature}
                  />
                  <img
                    src={feature5}
                    alt="Features"
                    className={styles.feature}
                  />
                  <img
                    src={feature6}
                    alt="Features"
                    className={styles.feature}
                  />
                  <img
                    src={feature3}
                    alt="Features"
                    className={styles.feature}
                  />
                  <img
                    src={feature4}
                    alt="Features"
                    className={styles.feature}
                  />
                </Slider>
              )
            } else {
              return (
                <Slider {...{ ...settings, slidesToShow: 4 }}>
                  <img
                    src={feature1}
                    alt="Features"
                    className={styles.feature}
                  />
                  <img
                    src={feature2}
                    alt="Features"
                    className={styles.feature}
                  />
                  <img
                    src={feature3}
                    alt="Features"
                    className={styles.feature}
                  />
                  <img
                    src={feature4}
                    alt="Features"
                    className={styles.feature}
                  />
                  <img
                    src={feature5}
                    alt="Features"
                    className={styles.feature}
                  />
                  <img
                    src={feature6}
                    alt="Features"
                    className={styles.feature}
                  />
                  <img
                    src={feature3}
                    alt="Features"
                    className={styles.feature}
                  />
                  <img
                    src={feature4}
                    alt="Features"
                    className={styles.feature}
                  />
                </Slider>
              )
            }
          }}
        </MediaQuery>
      </div>
    )
  }
}

const styles = {
  container: css({
    marginTop: '6rem',
    width: '120rem',
    [MEDIA_QUERY.SM]: {
      width: '100%',
      marginTop: '2rem',
      marginBottom: '4rem',
    },
  }),
  feature: css({
    objectFit: 'contain',
    cursor: 'pointer',
  }),
}
