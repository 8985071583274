import React from 'react'
import { css } from 'emotion'
import * as images from 'images'

const RewardsRightArrowButtonView = ({ disabled }) => (
  <img
    className={styles.rightArrow}
    src={images.rewardArrowRight}
    style={disabled ? { opacity: 0.5 } : {}}
    alt="Right Arrow"
  />
)

const styles = {
  rightArrow: css({
    position: 'absolute',
    top: '42%',
    right: '0.6rem',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  }),
}

export default RewardsRightArrowButtonView
