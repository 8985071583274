import React from 'react'
import { Text, CFView } from 'components'
import { css } from 'emotion'

const LocationSelectNameView = ({ selectedLocationName }) => (
  <CFView className={styles.location}>
    <Text h3 din white>
      {selectedLocationName}
    </Text>
  </CFView>
)

const styles = {
  location: css({
    cursor: 'pointer',
    marginLeft: '1rem',
    marginRight: '1rem',
  }),
}

export default LocationSelectNameView
