import React from 'react'
import { Container, Card, Space, Text } from 'components'
import { css } from 'emotion'
import { MEDIA_QUERY } from 'styles'

const PaymentView = ({
  CardNumberInputElement,
  ExpiryInputElement,
  CVCInputElement,
  SubmitButtonElement,
}) => {
  return (
    <Container>
      <div className={styles.content}>
        <div className={styles.border}>
          <Card>
            <div className={styles.container}>
              <Text heading h2 white bold>
                {'Credit Card'}
              </Text>
              <Space h2 />
              {CardNumberInputElement}
              <Space h3 />
              <div className={styles.expirycvc}>
                {ExpiryInputElement}
                <Space w2 />
                {CVCInputElement}
              </div>
              <Space h3 />
              {SubmitButtonElement}
            </div>
          </Card>
        </div>
      </div>
    </Container>
  )
}

const styles = {
  content: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  border: css({
    height: 'calc(100vh - 8rem)',
    width: '60rem',
    padding: '4rem 0 10rem 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [MEDIA_QUERY.SM]: {
      height: 'calc(100vh - 6rem)',
      width: '100%',
      padding: '2rem',
    },
  }),
  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  expirycvc: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
}

export default PaymentView
