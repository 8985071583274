import React, { Component } from 'react'
import { css } from 'emotion'
import { CFText, CFView, Space } from 'components'
import { about1, about2, about3 } from 'images'
import { MEDIA_QUERY } from 'styles'

class About extends Component {
  render() {
    return (
      <div className={styles.container}>
        <CFView column className={styles.description}>
          <CFText bold color="#C39100" className={styles.header}>
            DISCOVER TOM SUSHI
          </CFText>
          <div className={styles.content}>
            <CFText raleway h3 dark>
              Step into Tom Sushi for premium yet affordable Japanese cuisine.
              Our menu highlights the freshest and finest ingredients that Chef
              Tom is able to source from around the world.
            </CFText>
            <Space h3 />
            <CFText raleway h3 dark>
              Traditional Japanese flavours are combined with local, west coast
              elements to create a truly unique dining experience. Delight your
              senses with dishes that are beautifully presented and
              exceptionally delicious!
            </CFText>
          </div>
        </CFView>
        <div className={styles.composition}>
          <img src={about1} alt="About 1" className={styles.about1} />
          <img src={about2} alt="About 2" className={styles.about2} />
          <img src={about3} alt="About 3" className={styles.about3} />
        </div>
      </div>
    )
  }
}

const styles = {
  container: css({
    display: 'flex',
    width: '100%',
    maxWidth: '130rem',
    padding: '11rem 4rem 6rem 4rem',
    minHeight: '64rem',
    [MEDIA_QUERY.MD]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '4rem',
      padding: 0,
      minHeight: 'initial',
    },
  }),
  header: css({
    fontSize: 42,
    [MEDIA_QUERY.MD]: {
      fontSize: 34,
    },
  }),
  description: css({
    marginTop: '2rem',
    width: '40%',
    [MEDIA_QUERY.MD]: {
      width: '100%',
      textAlign: 'center',
    },
  }),
  content: css({
    flex: 1,
    marginRight: '4rem',
    marginTop: '4rem',
    [MEDIA_QUERY.MD]: {
      marginRight: 0,
      padding: '0 2rem 0 2rem',
    },
  }),
  composition: css({
    position: 'relative',
    width: '60%',
    marginTop: '2rem',
    marginLeft: '4rem',
    [MEDIA_QUERY.MD]: {
      marginTop: '6rem',
      marginLeft: 0,
      height: '35vh',
      width: '100%',
    },
  }),
  about1: css({
    width: '50%',
    position: 'absolute',
    zIndex: 12,
    objectFit: 'contain',
    left: '25%',
    top: '10rem',
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.05) translateY(-.5rem)',
      zIndex: 20,
    },
    '&:active': {
      transform: 'scale(1.05) translateY(-.5rem)',
      zIndex: 20,
    },
  }),
  about2: css({
    width: '49%',
    position: 'absolute',
    zIndex: 11,
    objectFit: 'contain',
    left: 0,
    top: '-2rem',
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.05) translateY(-.5rem)',
      zIndex: 20,
    },
    '&:active': {
      transform: 'scale(1.05) translateY(-.5rem)',
      zIndex: 20,
    },
  }),
  about3: css({
    width: '50%',
    position: 'absolute',
    zIndex: 10,
    objectFit: 'contain',
    right: 0,
    top: '2rem',
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.05) translateY(-.5rem)',
      zIndex: 20,
    },
    '&:active': {
      transform: 'scale(1.05) translateY(-.5rem)',
      zIndex: 20,
    },
  }),
}

export default About
