import React, { Fragment } from 'react'
import { CFView, Text, Space } from 'components'
import { isEmpty } from 'lodash'
import { moment } from 'cf-utils'

export default ({ hours }) =>
  [1, 2, 3, 4, 5, 6, 0].map(day => {
    return (
      <Fragment key={day}>
        <CFView row w="100%">
          <CFView w="8rem">
            <Text heading2 h4 white>{`${moment()
              .weekday(day)
              .format('ddd')}`}</Text>
          </CFView>
          <Text h4 white bold>
            {isEmpty(hours)
              ? ''
              : hours[day].open && hours[day].close
              ? `${moment(hours[day].open).format('LT')} - ${moment(
                  hours[day].close
                ).format('LT')}`
              : 'Closed'}
          </Text>
        </CFView>
        <Space h1 />
      </Fragment>
    )
  })
