import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import * as pages from 'cf-web-app/pages'

export default class Routes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" component={pages.Home} />
        </Switch>
      </Router>
    )
  }
}
