import React from 'react'
import { css } from 'emotion'
import { CFSelect } from 'components'
import PageFooter from './PageFooter'
import Hero from './Hero'
import Restaurant from './Restaurant'
import About from './About'
import Features from './Features'
import Chef from './Chef'
import SocialMedia from './SocialMedia'
import Contact from './Contact'
import { woodbg } from 'images'
import WelcomeModal from './WelcomeModal'
import { dispatch } from 'cf-web-app/store'

class HomeView extends React.Component {
  render() {
    const {
      HoursElement,
      ContactUsElement,
      OrderNowButtonElement,
      LogoElement,
    } = this.props
    return (
      <section className={styles.container}>
        <Hero OrderNowButtonElement={OrderNowButtonElement} />
        <div className={styles.woodbg}>
          <Features />
          <About />
          <Chef />
          <Restaurant />
          <SocialMedia />
        </div>
        <Contact
          HoursElement={HoursElement}
          LogoElement={LogoElement}
          ContactUsElement={ContactUsElement}
        />
        <PageFooter />
        <CFSelect selector={dispatch.restaurant.getWelcomeMessage}>
          <WelcomeModal />
        </CFSelect>
      </section>
    )
  }
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  }),
  woodbg: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: `url(${woodbg})`,
    backgroundSize: 'cover',
  }),
}

export default HomeView
