import React, { Component } from 'react'
import { css } from 'emotion'
import { CFLink } from 'components'
import { yelp, facebook, instagram, email } from 'images'
import { MEDIA_QUERY } from 'styles'

export default class SocialMedia extends Component {
  render() {
    return (
      <div className={styles.container}>
        <CFLink href="https://www.instagram.com/tomsushi_davie">
          <img src={instagram} alt="Instagram" className={styles.icon} />
        </CFLink>
        <CFLink href="https://www.facebook.com/pg/Tom-Sushi-411208622805962">
          <img src={facebook} alt="Facebook" className={styles.icon} />
        </CFLink>
        <CFLink href="https://www.yelp.ca/biz/tom-sushi-vancouver">
          <img src={yelp} alt="Yelp" className={styles.icon} />
        </CFLink>
        <a href="mailto:tom@tomsushi.ca">
          <img src={email} alt="Email" className={styles.icon} />
        </a>
      </div>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    height: '18rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginBottom: '2rem',
  }),
  icon: css({
    objectFit: 'contain',
    width: '7rem',
    margin: '1rem',
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
    [MEDIA_QUERY.MD]: {
      width: '6rem',
      margin: '.5rem',
    },
  }),
}
