import React from 'react'
import { CFLink, CFView, Text } from 'components'

export default ({ address, phoneNumber, email }) => (
  <CFView column>
    {address && (
      <CFView row mb="2rem">
        <CFView w="9rem">
          <Text heading2 h4 bold white>
            Address
          </Text>
        </CFView>
        <CFLink href="https://goo.gl/maps/yNLaBsuec2CsFo6F6">
          <Text h4 white bold>
            {address}
          </Text>
        </CFLink>
      </CFView>
    )}
    {phoneNumber && (
      <CFView row mb="2rem">
        <CFView w="9rem">
          <Text heading2 h4 bold white>
            Phone
          </Text>
        </CFView>
        <Text h4 white bold>
          {phoneNumber}
        </Text>
      </CFView>
    )}
    {email && (
      <CFView row>
        <CFView w="9rem">
          <Text heading2 h4 bold white>
            Email
          </Text>
        </CFView>
        <CFLink href={`mailto:${email}`}>
          <Text h4 white bold>
            {email}
          </Text>
        </CFLink>
      </CFView>
    )}
  </CFView>
)
