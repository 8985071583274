import React, { PureComponent } from 'react'
import { css } from 'emotion'
import { PageHeader } from 'components'
import { containerbg } from 'images'

class Container extends PureComponent {
  render() {
    const { children, className } = this.props
    const classNames = css(styles.container, className)
    return (
      <section className={classNames}>
        <PageHeader />
        {children}
      </section>
    )
  }
}

const styles = {
  container: css({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    background: `url(${containerbg})`,
    backgroundSize: 'contain',
  }),
}

export default Container
