import React, { Component } from 'react'
import { css } from 'emotion'
import PageHeader from './PageHeader'
import { CFLink, CFView, CFText } from 'components'
import { hero, dashDelivery, downloadButton, orderButton } from 'images'
import { MEDIA_QUERY } from 'styles'

class Hero extends Component {
  render() {
    return (
      <div className={styles.container}>
        <PageHeader />
        <CFView className={styles.buttons}>
          <CFText white className={styles.ctaText}>
            SECOND BEST RATED RESTAURANT IN CANADA - YELP
          </CFText>
          <CFLink href="https://order.codefusion.tech/restaurants/EmCDgDMiiBJIroFICSlM/locations/idVmQEH6OE0bCqD9Trqt">
            <img
              className={styles.orderButton}
              src={orderButton}
              alt="Download App"
            />
          </CFLink>
          <CFLink href="https://order.online/store/tom-sushi-vancouver-1755767/?delivery=true&hideModal=true">
            <img
              className={styles.downloadButton}
              src={dashDelivery}
              alt="Download App"
            />
          </CFLink>
        </CFView>
      </div>
    )
  }
  _handleOrderClick = () => {
    this.props.history.push('/menu')
  }
}
const styles = {
  container: css({
    height: '100vh',
    width: '100%',
    background: `url(${hero}) no-repeat`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    position: 'relative',
    alignItems: 'center',
    backgroundSize: 'cover',
    boxShadow: '0 .4rem .4rem rgba(0,0,0,.6)',
    zIndex: 0,
    [MEDIA_QUERY.MD]: {
      height: '100vh',
      backgroundAttachment: 'scroll',
    },
  }),
  buttons: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    maxWidth: '130rem',
    height: '8rem',
    backgroundColor: 'rgba(0,0,0,.5)',
    bottom: '0',
    right: '50%',
    transform: 'translateX(50%)',
    border: 'solid 1px white',
    padding: '0 3rem',
    [MEDIA_QUERY.MD]: {
      flexDirection: 'column',
      justifyContent: 'center',
      height: '11.5rem',
    },
  }),
  ctaText: css({
    WebkitTextStroke: '.5px white',
    fontSize: 28,
    textShadow: '0 .4rem .4rem rgba(0,0,0,.6)',
    [MEDIA_QUERY.LG]: {
      display: 'none',
    },
  }),
  downloadButton: css({
    width: '24rem',
    marginLeft: '15px',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.02)',
    },
    [MEDIA_QUERY.LG]: {
      marginLeft: 0,
      marginTop: '5px',
    },
  }),
  orderButton: css({
    width: '24rem',
    marginLeft: '15px',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.02)',
    },
    [MEDIA_QUERY.LG]: {
      marginLeft: 0,
      marginTop: '5px',
    },
  }),
}

export default Hero
