import React, { Component } from 'react'
import { css } from 'emotion'
import { CFView, PrimaryModal } from 'components'
import { dispatch } from 'cf-web-app/store'

export default class WelcomeModal extends Component {
  state = {
    showModal: false,
  }
  componentDidMount() {
    const welcomeMessage = dispatch.restaurant.getWelcomeMessage()
    if (welcomeMessage.title) {
      this.setState({ showModal: true })
    }
  }
  render() {
    return (
      <PrimaryModal
        title={dispatch.restaurant.getWelcomeMessage().title}
        isOpen={this.state.showModal}
        onRequestClose={() => this.setState({ showModal: false })}
      >
        <CFView className={styles.container}>
          <CFView column w="100%" pb="10px">
            <CFView h3 black textCenter>
              {dispatch.restaurant.getWelcomeMessage().body}
            </CFView>
          </CFView>
        </CFView>
      </PrimaryModal>
    )
  }
}

const styles = {
  container: css({
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '640px',
    '@media (max-width: 767px)': {
      width: '100%',
    },
  }),
}
