import React from 'react'
import { css } from 'emotion'
import { orderButton } from 'images'
import { MEDIA_QUERY } from 'styles'

export default () => (
  <img src={orderButton} alt="Order Now" className={styles.buttons} />
)

const styles = {
  buttons: css({
    width: '24rem',
    marginLeft: '15px',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.02)',
    },
    [MEDIA_QUERY.LG]: {
      marginLeft: 0,
      marginTop: '5px',
    },
  }),
}
