import React, { Component } from 'react'
import { css } from 'emotion'
import { CFText, CFView, Space } from 'components'
import { chefbg, chefcontent } from 'images'
import { MEDIA_QUERY } from 'styles'

export default class Chef extends Component {
  render() {
    return (
      <div className={styles.container}>
        <CFView column mt="4rem" maxSMStyle={{ marginTop: '2rem' }}>
          <div className={styles.content}>
            <CFView
              mb="6rem"
              maxSMStyle={{ marginBottom: '4rem', color: '#C39100' }}
            >
              <CFText bold className={styles.header}>
                CHEF TOM JEON
              </CFText>
            </CFView>
            <CFText h2 className={styles.text}>
              Chef Tom Jeon has been preparing sushi for over 10 years, honing
              his technical skills and cultivating his knowledge of traditional
              Japanese cuisine.
            </CFText>
            <Space h3 />
            <CFText h2 className={styles.text}>
              His dream of opening his own Japanese restaurant came to fruition
              in 2019. Chef Tom’s meticulous attention to detail is a reflection
              of his commitment to culinary excellence and quality service. Join
              a renowned chef as he crafts each dish with precision.
            </CFText>
          </div>
        </CFView>
        <img src={chefbg} alt="Tom Jeon" className={styles.chefbg} />
        <img src={chefcontent} alt="Tom Jeon" className={styles.chefcontent} />
      </div>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    maxWidth: '130rem',
    marginTop: '6rem',
    height: '70rem',
    color: 'white',
    position: 'relative',
    [MEDIA_QUERY.MD]: {
      marginBottom: '6rem',
      color: 'black',
    },
  }),
  chefbg: css({
    height: '70rem',
    width: '100%',
    top: 0,
    objectFit: 'cover',
    position: 'absolute',
    boxShadow: '.3rem .3rem .4rem rgba(0,0,0,.7)',
    [MEDIA_QUERY.MD]: {
      objectFit: 'contain',
      height: 'auto',
      position: 'static',
    },
  }),
  chefcontent: css({
    position: 'absolute',
    height: '80%',
    left: '5%',
    top: '50%',
    transform: 'translateY(-50%)',
    objectFit: 'contain',
    [MEDIA_QUERY.MD]: {
      display: 'none',
    },
  }),
  about: css({
    objectFit: 'contain',
    width: '60%',
    [MEDIA_QUERY.MD]: {
      width: '100%',
    },
  }),
  header: css({
    fontSize: 42,
    [MEDIA_QUERY.MD]: {
      fontSize: 34,
    },
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'absolute',
    left: '5%',
    top: '19%',
    width: '50rem',
    zIndex: 20,
    [MEDIA_QUERY.MD]: {
      position: 'static',
      marginRight: 0,
      width: '100%',
      padding: '0 2rem 0 2rem',
      marginBottom: '4rem',
      textAlign: 'center',
    },
  }),
  text: css({
    fontFamily: 'ubuntu',
  }),
}
