import React, { Component } from 'react'
import { css } from 'emotion'
import { CFText, CFView, Space } from 'components'
import { restaurant1, restaurant2, restaurant3 } from 'images'
import { MEDIA_QUERY } from 'styles'

export default class Restaurant extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.composition}>
          <img
            src={restaurant1}
            alt="Restaurant 1"
            className={styles.restaurant1}
          />
          <img
            src={restaurant2}
            alt="Restaurant 2"
            className={styles.restaurant2}
          />
          <img
            src={restaurant3}
            alt="Restaurant 3"
            className={styles.restaurant3}
          />
        </div>
        <CFView
          column
          ml="4rem"
          maxSMStyle={{ marginLeft: 0 }}
          className={styles.description}
        >
          <CFText bold color="#C39100" className={styles.header}>
            OUR RESTAURANT
          </CFText>
          <div className={styles.content}>
            <CFText raleway h3 dark>
              Located in the heart of Downtown Vancouver’s Davie Street, Tom
              Sushi presents the same simplicity that characterizes the chef’s
              authentic passion and warm hospitality.
            </CFText>
            <Space h3 />
            <CFText raleway h3 dark>
              The atmosphere is one of understated elegance, designed to bring
              comfort to each guest. Come and explore the friendly ambience of
              Tom Sushi, where our staff is committed to making your visit
              extraordinary.
            </CFText>
          </div>
        </CFView>
      </div>
    )
  }
}

const styles = {
  container: css({
    display: 'flex',
    width: '100%',
    maxWidth: '130rem',
    minHeight: '64rem',
    padding: '12rem 4rem 2rem 4rem',
    [MEDIA_QUERY.MD]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
      marginTop: '3rem',
      padding: 0,
    },
  }),
  header: css({
    fontSize: 42,
    [MEDIA_QUERY.MD]: {
      fontSize: 34,
    },
  }),
  description: css({
    marginTop: '2rem',
    width: '37%',
    [MEDIA_QUERY.MD]: {
      margin: 0,
      width: '100%',
      textAlign: 'center',
    },
  }),
  content: css({
    flex: 1,
    marginTop: '4rem',
    [MEDIA_QUERY.MD]: {
      padding: '0 2rem 0 2rem',
    },
  }),
  composition: css({
    position: 'relative',
    width: '63%',
    marginRight: '4rem',
    marginTop: '2rem',
    [MEDIA_QUERY.MD]: {
      marginRight: 0,
      marginTop: '6rem',
      height: '35vh',
      width: '100%',
    },
  }),
  restaurant1: css({
    width: '45%',
    position: 'absolute',
    zIndex: 11,
    objectFit: 'contain',
    transition: 'all .2s',
    left: '20%',
    top: '10rem',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.05) translateY(-.5rem)',
      zIndex: 20,
    },
  }),
  restaurant2: css({
    width: '45%',
    position: 'absolute',
    zIndex: 10,
    objectFit: 'contain',
    transition: 'all .2s',
    left: 0,
    top: '-2rem',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.05) translateY(-.5rem)',
      zIndex: 20,
    },
  }),
  restaurant3: css({
    width: '55%',
    position: 'absolute',
    zIndex: 12,
    objectFit: 'contain',
    transition: 'all .2s',
    right: 0,
    top: '2rem',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.05) translateY(-.5rem)',
      zIndex: 20,
    },
  }),
}
