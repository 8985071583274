import React, { Component } from 'react'
import { css } from 'emotion'
import { Logo } from 'cf-web-app/components'
import { MEDIA_QUERY } from 'styles'

class PageHeader extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.location}>
            <Logo />
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    height: '8rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    paddingTop: '1.5rem',
    zIndex: 10,
  }),
  innerContainer: css({
    width: '90%',
    height: '100%',
    maxWidth: '116rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    [MEDIA_QUERY.SM]: {
      paddingTop: '1rem',
    },
  }),
  location: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [MEDIA_QUERY.SM]: {
      flexDirection: 'column',
    },
  }),
}

export default PageHeader
