import React from 'react'
import { CFView, Text } from 'components'
import { css } from 'emotion'
import { logo } from 'images'
import { MEDIA_QUERY } from 'styles'

export default ({
  NameInputElement,
  PhoneInputElement,
  CancelButtonElement,
  SubmitButtonElement,
}) => (
  <div className={styles.container}>
    <CFView column w="100%">
      <CFView column w="100%" center mb="4rem">
        <img src={logo} alt="Tom Sushi" width="300" />
      </CFView>
      <CFView mb="2rem">
        <Text heading2 h3 center>
          To complete your order, enter the following:
        </Text>
      </CFView>
      {NameInputElement && <CFView mb="2rem">{NameInputElement}</CFView>}
      {PhoneInputElement && <CFView mb="2rem">{PhoneInputElement}</CFView>}
      <CFView row mt="2rem" center>
        {CancelButtonElement}
        <CFView w="2rem" />
        {SubmitButtonElement}
      </CFView>
    </CFView>
  </div>
)

const styles = {
  container: css({
    backgroundColor: '#232323',
    color: 'white',
    padding: '4rem',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '48rem',
    [MEDIA_QUERY.SM]: {
      padding: '2rem',
      width: '100%',
    },
  }),
}
