import React, { Component } from 'react'
import { css } from 'emotion'
import { CFLink, CFView, Space, CFText } from 'components'
import { appStore, googlePlay, locationMap } from 'images'
import { MEDIA_QUERY } from 'styles'

class Contact extends Component {
  render() {
    const { HoursElement, ContactUsElement } = this.props
    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <CFLink href="https://goo.gl/maps/yNLaBsuec2CsFo6F6">
            <img src={locationMap} alt="Location" className={styles.map} />
          </CFLink>
          <Space h2 />
          <div className={styles.column}>
            <CFText heading2 h2 bold color="#C39100">
              LOCATION
            </CFText>
            <Space h2 />
            {ContactUsElement}
            <Space h2 />
            <CFText heading2 h2 bold color="#C39100">
              DOWNLOAD APP
            </CFText>
            <Space h2 />
            <CFView column>
              <CFLink href="https://itunes.apple.com/us/app/tom-sushi/id1462392942">
                <img className={styles.mobile} src={appStore} alt="App Store" />
              </CFLink>
              <Space h1 />
              <CFLink href="https://play.google.com/store/apps/details?id=tech.codefusion.tomsushi">
                <img
                  className={styles.mobile}
                  src={googlePlay}
                  alt="Goodle Play"
                />
              </CFLink>
            </CFView>
          </div>
          <Space h2 />
          <div className={styles.column}>
            <CFText heading2 h2 bold color="#C39100">
              BUSINESS HOURS
            </CFText>
            <Space h2 />
            <div className={styles.hours}>{HoursElement}</div>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    height: '46rem',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#171717',
    [MEDIA_QUERY.MD]: {
      height: '100%',
    },
  }),
  innerContainer: css({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    padding: '4rem 0 4rem 0',
    maxWidth: '115rem',
    zIndex: 1,
    position: 'relative',
    [MEDIA_QUERY.MD]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '2rem',
    },
  }),
  contactbg: css({
    maxHeight: '52rem',
    width: '100%',
    objectFit: 'fill',
    objectPosition: 'bottom',
    position: 'absolute',
    bottom: 0,
    [MEDIA_QUERY.MD]: {
      height: '103%',
      width: '100%',
      maxHeight: '103%',
      objectFit: 'cover',
    },
  }),
  hours: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '35rem',
  }),
  mobile: css({
    height: '4rem',
    objectFit: 'contain',
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }),
  map: css({
    width: '100%',
    maxWidth: '420px',
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.01)',
    },
    [MEDIA_QUERY.MD]: {
      marginTop: '2rem',
    },
  }),
  column: css({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '4rem',
    [MEDIA_QUERY.MD]: {
      marginTop: 0,
      alignItems: 'center',
    },
  }),
}

export default Contact
