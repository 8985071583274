import React from 'react'
import { Space, Card } from 'components'
import { css } from 'emotion'
import * as images from 'images'
import { MEDIA_QUERY } from 'styles'

const UserLoadedView = ({
  UserDetailsElement,
  LogoutButtonElement,
  OrderHistory,
}) => (
  <div className={styles.content}>
    <div className={styles.border}>
      <Card className={styles.userInfo}>
        <img src={images.user} alt="User" width="140rem" />
        {UserDetailsElement}
        <Space h2 />
        {LogoutButtonElement}
        <Space h1 />
      </Card>
      <Space height="2rem" width="4rem" />
      <Card className={styles.orderHistory}>
        <OrderHistory />
      </Card>
    </div>
  </div>
)

const styles = {
  content: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  }),
  border: css({
    width: '100%',
    maxWidth: '120rem',
    marginBottom: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [MEDIA_QUERY.SM]: {
      alignItems: 'stretch',
      padding: '0 2rem 2rem 2rem',
      flexDirection: 'column',
    },
  }),
  userInfo: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  orderHistory: css({
    flex: 1.5,
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
}

export default UserLoadedView
